import marketingParams from "../modules/marketingParams";
import preLandGTM from "./preLandGTM";
import changePreLanStep from "./changePreLanStep";

document.addEventListener("DOMContentLoaded", changePreLanStep);

function preLand() {
    preLandGTM();
    marketingParams();
}

preLand();
