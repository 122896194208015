export default function () {
    const mirrorName = document.body.dataset.site
    const gtmTag = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    let gtmId = ''

    switch (mirrorName) {
        case 'preLand_1':
            gtmId = 'GTM-TH3CJHRN'
            break;
    }

    if (gtmId) {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            document.head.appendChild(j);
        })(window, document, 'script', 'dataLayer', gtmId);

        gtmTag.innerHTML = '';

        iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
        iframe.height = "0";
        iframe.width = "0";
        iframe.style.display = "none";
        iframe.style.visibility = "hidden";

        gtmTag.appendChild(iframe);

        document.body.prepend(gtmTag)
    }
}
